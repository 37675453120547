// Login.js
import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { loginRequest } from './authConfig';

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { getLocation } = useRouter();
  const location = getLocation();

  const [routeChanged, setRouteChanged] = useState(false);

  useEffect(() => {
    if (location.pathname === '/' && !isAuthenticated) {
      setRouteChanged(true);
    }
  }, [location]);

  useEffect(() => {
    if (routeChanged) {
      const handleLogin = async () => {
        try {
          const response = await instance.loginRedirect(loginRequest);
        } catch (error) {
          console.log(error);
        }
      };
      if (!isAuthenticated) {
        handleLogin();
      }
    }
  }, [isAuthenticated, routeChanged]);

  return <React.Fragment />;
};

export default Login;