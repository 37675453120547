import React, { useEffect, useState } from 'react';
import { Table } from '@abyss/web/ui/Table';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { MlreLoader } from '@src/common/MlreAbyssComponent';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';

import { BASE_URL } from '../utils/endpoints';
import env from '../../../env.json';

export const EmpolyerConversionCheckTable = ({ data, breadcrums }) => {
  const { navigate } = useRouter();
  const { userRolesGroups, msid } = useAuth();
  const [reistributeList, setreistributeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [redistributeStatus, setRedistributeStatus] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [validatePaymentData, setValidatePaymentData] = useState();

  const getRedistributeList = async () => {
    const payload = {
      rbtRptYr: data.rbtReportYr,
      srcSysCd: data.srcSysCd,
      srcSysContrId: data.policyNum,
    };

    const result = await axios.post(
      `${BASE_URL}payment/redistributed/list`,
      payload,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (result) {
      setreistributeList(result.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const hasUpdateAccess = env.Pending_Redistribute_Approval.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups, env]);

  useEffect(() => {
    const payload = {
      rbtReportYr: data.rbtReportYr,
      srcSysCd: data.srcSysCd,
      policyNum: data.policyNum,
    };

    axios
      .post(`${BASE_URL}checkReissue/approveCheck`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setRedistributeStatus(res.data[0]?.count === 0);
      })
      .catch((error) => {
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      });
  }, []);

  useEffect(() => {
    if (data) {
      getRedistributeList();
    }
  }, [data]);

  const columns = [
    { name: 'Policy Number', key: 'srcSysContrId' },
    { name: 'Legal Entity ID', key: 'lglEntyId' },
    { name: 'MLRE Case Size', key: 'mlrCseSz' },
    { name: 'Situs State', key: 'mlrSitusStCd' },
    { name: 'Check Number', key: 'pmtDocNbr' },
    { name: 'Check status', key: 'payStsCd' },
  ];

  const validatePayment = async () => {
    try {
      const payload = {
        payDocNum: data?.payDocNbr,
        modifiedDateTime: data?.modifiedDateTime,
      };
      const result = await axios.post(`${BASE_URL}payment/validate`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      if (result?.data) {
        setValidatePaymentData(result?.data);
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleSubmit = async () => {
    if (!redistributeStatus) {
      alert('Void request failed for check redistribution.');
    } else {
      const payload = [data];
      axios
        .post(`${BASE_URL}checkReissue/updateApproveRedistribute`, payload, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          alert(
            'Approval request submitted successfully for check redistribution'
          );
          navigate('/pending-redistribute-approval');
        })
        .catch((error) => {
          alert(
            'An error occurred while processing your request. Please try again later.'
          );
        });
    }
  };

  const handleValidatedSubmit = async () => {
    const validationResult = await validatePayment();
    if (validationResult === 'validation passed') {
      handleSubmit();
    } else {
      alert('Failed: Current data has been modified by other user');
    }
  };

  return (
    <React.Fragment>
      {redistributeStatus ? (
        <Layout.Stack
          css={{
            alignContent: 'center',
          }}
        >
          <Text size="md" color="#000000">
            All the checks for this policy have passed validations.
          </Text>
          <Text size="md" color="#000000">
            Following checks are issued for this policy.
          </Text>
          <Text size="md" color="#000000">
            Please click submit to void all the checks.
          </Text>
        </Layout.Stack>
      ) : (
        <Layout.Stack
          css={{
            alignContent: 'center',
          }}
        >
          <Text size="md" color="#000000">
            All the checks for this policy have not passed validations.
          </Text>
          <Text size="md" color="#000000">
            Following checks are issued for this policy.
          </Text>
          <Text size="md" color="#000000">
            Redistribution of checks cannot be proceeded further.
          </Text>
        </Layout.Stack>
      )}

      <br />
      {isLoading ? (
        <Grid>
          <MlreLoader />
        </Grid>
      ) : (
        <Grid>
          <Table
            title="Table Scroll"
            columns={columns}
            rows={reistributeList}
          />
        </Grid>
      )}
      <Layout.Group alignLayout="center">
        <Button
          onClick={handleValidatedSubmit}
          css={{
            borderRadius: '4px',
            marginTop: '400px',
          }}
          isDisabled={isButtonDisabled}
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            breadcrums.pop();
            navigate('/pending-redistribute-approval');
          }}
          css={{
            borderRadius: '4px',
            marginLeft: '25px',
            marginTop: '400px',
          }}
        >
          Dismiss
        </Button>
      </Layout.Group>
    </React.Fragment>
  );
};
