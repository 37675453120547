import React, { useState, useEffect } from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import axios from 'axios';
import { NameLookUpTableModal } from './Modal';
import { MlreLoader } from '../../common/MlreAbyssComponent';
import { BASE_URL } from '../utils/endpoints';

export const SubscriberForm = ({ onSearch }) => {
  const [searchFieldName, setSearchFieldName] = useState('');
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [selectBoxData, setSelectBoxData] = useState({
    rebateYears: [],
    srcSysCodes: [],
  });
  const [year, setYear] = useState([]);
  const [systemId, setSystemId] = useState([]);
  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      rebateYear: '',
      srcSysCd: '',
    },
  });

  const formvalues = form.getValues();

  const handleSelectValue = (fieldName, value) => {
    if (fieldName === 'firstName') {
      form.setValue('sbscrFstNm', value);
      formvalues.sbscrFstNm = value;
    } else if (fieldName === 'lastName') {
      form.setValue('sbscrLstNm', value);
      formvalues.sbscrLstNm = value; // Update the formvalues object
    }
  };

  const payload = {
    srcSysCd: formvalues?.srcSysCd,
    policy: formvalues?.policy,
    name: formvalues?.sbscrLstNm,
  };

  const handlleNameSearch = async (fieldName) => {
    let url = '';
    let nameKey = '';

    if (fieldName === 'firstName') {
      url = `${BASE_URL}subscriber/firstname`;
      nameKey = 'firstName';
    } else if (fieldName === 'lastName') {
      url = `${BASE_URL}subscriber/lastname`;
      nameKey = 'lastName';
    }

    const payload = {
      srcSysCd: formvalues?.srcSysCd,
      policy: formvalues?.policy,
      name: formvalues?.[
        fieldName === 'firstName' ? 'sbscrFstNm' : 'sbscrLstNm'
      ],
    };

    setSearchFieldName(fieldName);
    setOpenSearchModal(true);
    const result = await axios.post(url, payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
        'Content-Type': 'application/json',
      },
    });
    setNameList(result.data);
  };

  const onSubmit = async (searchData) => {
    setIsSearchDisabled(true);
    // setLoading(true);
    if (isLoading) {
      try {
        const { data, error } = await axios.post(
          `${BASE_URL}subscriber/subsearch`,
          { ...searchData, pageSize: 1, pageNum: 0 },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (data && data.length) {
          localStorage.setItem(
            'subscriberFormData',
            JSON.stringify(form.getValues())
          );
          onSearch(searchData);
          setIsSearchDisabled(false);
          setLoading(false);
        } else {
          alert('Policy not found');
          setIsSearchDisabled(false);
          setLoading(false);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      }
    }
  };

  useEffect(() => {
    if (!form.formState.isValid) {
      setLoading(false);
    }
  }, [form.formState]);

  const fetchData = async () => {
    // setIsDropDownLoading(true);
    try {
      const { data, error } = await axios.get(
        `${BASE_URL}subscriber/dropdowns`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const uniqueValues = {
        year: new Set(),
        systemId: new Set(),
      };

      data.rebateYears.forEach((item) => {
        if (item.rbtReportYr) {
          uniqueValues.year.add(item.rbtReportYr);
        }
      });

      data.srcSysCodes.forEach((item) => {
        if (item.sysCode) {
          uniqueValues.systemId.add(item.sysCode);
        }
      });

      const yearArray = Array.from(uniqueValues.year);
      const systemIdArray = Array.from(uniqueValues.systemId);

      setYear(yearArray);
      setSystemId(systemIdArray);

      const savedYear = localStorage.getItem('selectedYear');
      const savedSystemId = localStorage.getItem('selectedSystemId');

      const savedSubscriberFormData =
        localStorage.getItem('subscriberFormData');

      if (savedSubscriberFormData) {
        const parsedData = JSON.parse(savedSubscriberFormData);
        // Ensure rebateYear is a string
        if (parsedData.rebateYear) {
          parsedData.rebateYear = parsedData.rebateYear.toString();
        }
        form.reset(parsedData);
      } else {
        if (savedYear) {
          form.setValue('rebateYear', savedYear);
        } else if (yearArray.length > 0) {
          localStorage.setItem('selectedYear', yearArray[0]);
          form.setValue('rebateYear', yearArray[0]);
        }

        if (savedSystemId) {
          form.setValue('srcSysCd', savedSystemId);
        } else if (systemIdArray.length > 0) {
          localStorage.setItem('selectedSystemId', systemIdArray[0]);
          form.setValue('srcSysCd', systemIdArray[0]);
        }
      }

      const rebateYearsRows = [];
      const srcSysCodesRows = [];
      data?.rebateYears?.map(({ rbtReportYr }) => {
        rebateYearsRows.push({
          value: rbtReportYr.toString(),
          label: rbtReportYr.toString(),
        });
      });
      data?.srcSysCodes?.map(({ sysCode }) => {
        srcSysCodesRows.push({ value: sysCode, label: sysCode });
      });
      setSelectBoxData({
        rebateYears: rebateYearsRows,
        srcSysCodes: srcSysCodesRows,
      });
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    } finally {
      setIsDropDownLoading(false);
    }
  };

  useEffect(() => {
    const savedSubscriberFormData = localStorage.getItem('subscriberFormData');
    if (savedSubscriberFormData) {
      const parsedData = JSON.parse(savedSubscriberFormData);
      Object.keys(parsedData).forEach((key) => {
        form.setValue(key, parsedData[key]);
      });
    }

    fetchData();
  }, []);

  return (
    <React.Fragment>
      {/* {isLoading && (
        <div className="mlre-loader-overlay">
          <MlreLoader loaderWidth="1000px" />
        </div>
      )} */}
      <NameLookUpTableModal
        searchFieldName={searchFieldName}
        isOpen={openSearchModal}
        onClose={() => {
          setOpenSearchModal(false);
        }}
        nameList={nameList}
        onSelectValue={handleSelectValue}
        sourceSystem={form.getValues()?.srcSysCd}
        inputName={
          searchFieldName === 'lastName'
            ? form.getValues()?.sbscrLstNm
            : form.getValues()?.sbscrFstNm
        }
      />
      {/* {!isLoading && ( */}
      <FormProvider state={form} onSubmit={onSubmit}>
        <SelectInput
          label="Rebate Year"
          model="rebateYear"
          isSearchable={false}
          isLoading={isDropDownLoading}
          options={selectBoxData.rebateYears}
          value={formvalues.rebateYear}
        />

        <SelectInput
          label="Source System"
          model="srcSysCd"
          isSearchable={false}
          isLoading={isDropDownLoading}
          options={selectBoxData.srcSysCodes}
        />

        <TextInput
          label="Policy#"
          model="policy"
          validators={{ required: true }}
        />

        <Grid
          css={{
            width: '100%',
            padding: '10px 0px 0px 0px',
            alignItems: 'flex-end',
          }}
        >
          <Grid.Col span={11}>
            <TextInput label="Last Name" model="sbscrLstNm" />
          </Grid.Col>
          <Grid.Col span={1} css={{ cursor: 'pointer' }}>
            <IconMaterial
              icon="search"
              onClick={() => {
                handlleNameSearch('lastName');
              }}
            />
          </Grid.Col>
        </Grid>

        <Grid
          css={{
            width: '100%',
            padding: '10px 0px 10px 0px',
            alignItems: 'flex-end',
          }}
        >
          <Grid.Col span={11}>
            <TextInput label="First Name" model="sbscrFstNm" />
          </Grid.Col>
          <Grid.Col span={1} css={{ cursor: 'pointer' }}>
            <IconMaterial
              icon="search"
              onClick={() => {
                handlleNameSearch('firstName');
              }}
            />
          </Grid.Col>
        </Grid>

        <TextInput label="Last 4 digits of SSN" model="lst4DgtSsn" />

        <TextInput label="Alternate ID" model="altId" />

        <Button
          type="submit"
          isDisabled={isSearchDisabled}
          isLoading={isLoading}
          ariaLoadingLabel="Importing data"
          onClick={() => {
            return setLoading(!isLoading);
          }}
          style={{ marginTop: '15px', marginRight: '5px' }}
        >
          Search
        </Button>

        <Button
          onClick={() => {
            localStorage.removeItem('subscriberFormData');
            form.reset({
              rebateYear: year[0],
              srcSysCd: systemId[0],
            });
            fetchData();
          }}
          style={{ marginTop: '15px', marginLeft: '5px' }}
        >
          Reset
        </Button>
      </FormProvider>
      {/* )} */}
    </React.Fragment>
  );
};
