import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { Flex } from '@abyss/web/ui/Flex';
import { Label } from '@abyss/web/ui/Label';
import { Text } from '@abyss/web/ui/Text';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import { SubHeader } from '@src/common/components';
import { CustomerSearchIcon } from '@src/components/customer/CustomerHelpIcons';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Alert } from '@abyss/web/ui/Alert';
import useAuth from 'src/auth/useAuth';
import env from '../../../env.json';
import { WrapperDiv, mediumSize } from '../../common/utils/StyleConstant';
import { subscriberCLabels } from './constants';
import { BASE_URL } from '../utils/endpoints';
import styles from '../../styles/styles.module.css';
import { SubscriberCloneSelectSource } from './SubscriberHelpIcon';

const SelectSourceAOG = () => {
  const isMedium = useMediaQuery(mediumSize);
  const { navigate, getLocation } = useRouter();
  const [tableData, setTableData] = useState();
  const { state } = getLocation();
  const payload = state?.searchData;
  const [subData, setSubData] = useState(state?.searchData);
  const [cloneTableRows, setCloneTableRows] = useState([]);
  const { token } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const { breadItem } = state;
  const { radioValue } = state;
  const selectedClone = useRef(subData?.checkValue?.checkValue);
  const [sucessText, setSucessText] = useState('');
  const [seletedRow, setSeletedRow] = useState({});
  const [initialCheckboxValues, setInitialCheckboxValues] = useState([]);
  const { userRolesGroups } = useAuth();
  const [isCheckedAccess, setIsCheckedAccess] = useState(false);

  useEffect(() => {
    const hasCheckAccess = env.Subscriber_Clone_Source_Edit.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasCheckAccess) {
      setIsCheckedAccess(true);
    } else {
      setIsCheckedAccess(false);
    }
  }, [userRolesGroups]);

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}subscriber-clone/subclonesearch`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setTableData(data);
      setLoading(false);
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (payload) {
      fetchData();
    }
  }, [payload, state]);
  const fetchCloneData = async () => {
    if (tableData?.length > 0) {
      const payload = {
        agrOperGrpId: tableData[0]?.agrOperGrpId,
        srcSysCd: subData?.srcSysCd,
        srcSysCustContrId: subData?.srcSysCustContrId,
      };

      try {
        const { data } = await axios.post(
          `${BASE_URL}subscriber-clone/list`,
          payload,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setCloneTableRows(data);
        setInitialCheckboxValues(
          data.map((row) => {
            return row.cloneCheckBox.checkValue;
          })
        );
      } catch (error) {
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      }
    }
  };

  const handleReset = () => {
    setCloneTableRows((prevRows) => {
      return prevRows.map((row, index) => {
        return {
          ...row,
          cloneCheckBox: { checkValue: initialCheckboxValues[index] }, // Reset to initial values
        };
      });
    });
    selectedClone.current = initialCheckboxValues.some((value) => {
      return value === 1;
    });
  };

  useEffect(() => {
    fetchCloneData();
  }, [tableData]);

  const handleSave = async () => {
    try {
      if (selectedClone.current) {
        const payload = {
          rebateYear: seletedRow?.rebateYear,
          srcSysCd: seletedRow?.srcSysCd,
          tgtAggOpGrpId: tableData[0]?.agrOperGrpId,
          tgtAggrGrpId: tableData[0]?.agrGrpId,
          tgtInclInRbtInd: tableData[0]?.inclInRbtInd,
          tgtSbscrCnt: tableData[0]?.sbcrCnt,
          srcAggrOperGrpId: seletedRow?.agrOperGrpId,
          srcAggrGrpId: seletedRow?.agrGrpId,
          srcInclInRbtInd: seletedRow?.inclInRbtInd,
          srcSbscrCnt: seletedRow?.sbcrCnt,
        };
        const { data } = await axios.post(
          `${BASE_URL}subscriber-clone/clone`,
          payload,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        fetchCloneData();
        alert('Subscriber Cloned Successfully');
      } else {
        const payload = {
          rebateYear: '2023',
          srcSysCd: seletedRow?.srcSysCd,
          tgtAggOpGrpId: seletedRow?.tgtAggOpGrpId,
          tgtAggrGrpId: seletedRow?.tgtAggrGrpId,
          srcAggrOperGrpId: seletedRow?.agrOperGrpId,
          srcAggrGrpId: seletedRow?.agrGrpId,
        };
        const { data } = await axios.post(
          `${BASE_URL}subscriber-clone/delete`,
          payload,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        fetchCloneData();
        alert('Subscriber Deleted Successfully');
      }
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: subscriberCLabels.SOURCE_SYSTEM,
        accessor: 'srcSysCd',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.POLICY_NUMBER,
        accessor: 'srcSysCustContrId',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.LEGAL_ENTITY,
        accessor: 'lglEntyId',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.CASE_SIZE,
        accessor: 'mlrecsSz',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.SITUS_STATE,
        accessor: 'mlrestCd',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.BUSINESS_UNIT,
        accessor: 'glBuId',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.LOCATION,
        accessor: 'glLocCd',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.REBATE_INDICATOR,
        accessor: 'inclInRbtInd',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.SUBSCRIBER_COUNT,
        accessor: 'sbcrCnt',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.CLONE,
        accessor: 'cloneCheckBox',
        disableSortBy: false,
        Cell: ({ row, value, cellActions }) => {
          const isDisabled = false;
          return (
            <label htmlFor={row.id}>
              <input
                id={row.id}
                disabled={isDisabled}
                type="checkbox"
                checked={value?.checkValue === 1}
                onChange={() => {
                  selectedClone.current = !value?.checkValue;
                  setSeletedRow(row.original);
                  cellActions.modifyRow(row, {
                    cloneCheckBox: { checkValue: !value?.checkValue },
                  });
                }}
              />

              <IconSymbol
                icon={
                  value?.checkValue ? 'check_box' : 'check_box_outline_blank'
                }
                size="md"
                style={{ cursor: 'pointer' }}
              />
            </label>
          );
        },
      },
    ];
  }, []);

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              {/* <Flex style={{ marginTop: '10px', marginBottom: '30px' }}>
                <Label size="md">{subscriberCLabels.REBATE_REPORT_YEAR}</Label>
                <Text
                  size="md"
                  style={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  2023
                </Text>
                <Label
                  size="md"
                  style={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  {subscriberCLabels.RUN_TYPE}
                </Label>
                <Text
                  size="md"
                  style={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  F
                </Text>
              </Flex> */}
              <SubHeader
                breadItem={[
                  ...breadItem,
                  {
                    title: 'Select Source AOG',
                    href: '/subscriber-clone-result',
                  },
                ]}
                infoTitle="Select Source AOG"
                InfoContent={SubscriberCloneSelectSource}
              />
              <Flex>
                <Label size="md" css={{ color: '#000' }}>
                  {subscriberCLabels.REBATE_REPORT_YEAR} :
                </Label>
                <Text
                  size="md"
                  css={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  2023
                </Text>
                <Label
                  size="md"
                  style={{
                    marginLeft: '25px',
                    marginRight: '25px',
                    color: '#000',
                  }}
                >
                  {subscriberCLabels.RUN_TYPE}:
                </Label>
                <Text
                  size="md"
                  css={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  F
                </Text>
              </Flex>
              <Label size="md" style={{ marginTop: '10px', color: '#000' }}>
                {subscriberCLabels.TARGET_AOG}
              </Label>

              {isLoading ? (
                <MlreLoader />
              ) : (
                <React.Fragment>
                  {/* <Flex style={{ marginTop: '10px' }}>
                    <Label size="md">{subscriberCLabels.SOURCE_SYSTEM}:</Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.srcSysCd}
                    </Text>
                    <Label
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {subscriberCLabels.POLICY_NUMBER}:
                    </Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.srcSysCustContrId}
                    </Text>
                    <Label
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {subscriberCLabels.LEGAL_ENTITY}:
                    </Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.lglEntyId}
                    </Text>
                    <Label
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {subscriberCLabels.CASE_SIZE}:
                    </Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.mlrecsSz}
                    </Text>
                  </Flex>
                  <Flex style={{ marginTop: '10px' }}>
                    <Label size="md">{subscriberCLabels.SITUS_STATE}:</Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.mlrestCd}
                    </Text>
                    <Label
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {subscriberCLabels.BUSINESS_UNIT}:
                    </Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.glBuId}
                    </Text>
                    <Label
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {subscriberCLabels.LOCATION}:
                    </Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.glLocCd}
                    </Text>
                    <Label
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {subscriberCLabels.SUBSCRIBER_COUNT}:
                    </Label>
                    <Text
                      size="md"
                      style={{ marginLeft: '25px', marginRight: '25px' }}
                    >
                      {tableData[0]?.sbcrCnt}
                    </Text>
                  </Flex> */}
                  <Grid
                    css={{
                      display: 'flex',
                      // paddingLeft: '12px',
                      paddingTop: '14px',
                    }}
                  >
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.SOURCE_SYSTEM}
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">:{tableData[0]?.srcSysCd} </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.POLICY_NUMBER}:
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">
                        :{tableData[0]?.srcSysCustContrId}
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.LEGAL_ENTITY}:
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">:{tableData[0]?.lglEntyId}</Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.CASE_SIZE}:
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">:{tableData[0]?.mlrecsSz}</Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.SITUS_STATE}:{' '}
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">:{tableData[0]?.mlrestCd}</Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.BUSINESS_UNIT}:
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">:{tableData[0]?.glBuId}</Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.LOCATION}:
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">:{tableData[0]?.glLocCd}</Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000" fontWeight="bold">
                        {subscriberCLabels.SUBSCRIBER_COUNT}:
                      </Text>
                    </Grid.Col>
                    <Grid.Col
                      css={{ paddingBottom: '0px' }}
                      span={{ xs: 2, md: 3, lg: 4, xl: 2 }}
                    >
                      <Text color="#000">:{tableData[0]?.sbcrCnt}</Text>
                    </Grid.Col>
                  </Grid>
                  <Label
                    size="md"
                    style={{
                      marginTop: '24px',
                      marginBottom: '20px',
                      // marginLeft: '16px',
                      color: '#000',
                    }}
                  >
                    {subscriberCLabels.SOURCE_AOG}
                  </Label>
                  {/* <MlreDataTable data={tableData} columns={columns} /> */}
                  <MlreDataTable data={cloneTableRows} columns={columns} />
                </React.Fragment>
              )}
              <WrapperDiv>
                {/* <Button>{subscriberCLabels.DOWNLOAD}</Button> */}
              </WrapperDiv>
              <Alert
                title="Subscriber Clone"
                variant="success"
                isVisible={sucessText}
              >
                {sucessText}
              </Alert>
              <Flex direction="row" justify="center" className={styles.Layout}>
                <Button
                  type="submit"
                  className={styles.Button}
                  onClick={handleSave}
                  isDisabled={isCheckedAccess}
                >
                  {subscriberCLabels.SAVE}
                </Button>
                <Button
                  className={styles.Button}
                  onClick={handleReset}
                  isDisabled={isCheckedAccess}
                >
                  {subscriberCLabels.RESET}
                </Button>
              </Flex>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(SelectSourceAOG);
