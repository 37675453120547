import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import { DataSearchLabel } from '../../common/MlreAbyssComponent/DataSearchLabel';
import { MlreDataTable, MlreLoader } from '../../common/MlreAbyssComponent';
import { ConfigurationSearchForm } from './ConfigurationSearchForm';
import { ConfigurationFormData } from './Constants';
import { BASE_URL } from '../utils/endpoints';

export const ConfigurationTable = ({ searchData }) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);

  const fetchData = async () => {
    try {
      if (searchData && typeof searchData === 'object') {
        const response = await axios.post(
          `${BASE_URL}configuration/search-config-with-params`,
          {
            rebateReportYear: searchData.rebateReportYear,
            parameterType: searchData.parameterType,
            parameterCategory: searchData.parameterCategory,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setTableData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (searchData) {
      fetchData();
    }
  }, [searchData]);

  useEffect(() => {
    if (refreshData) {
      fetchData();
      setRefreshData(false);
    }
  }, [refreshData]);

  const handleEdit = (rowData) => {
    setSelectedRowData(rowData);
    setShowEditForm(true);
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Detail',
        accessor: 'col1',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => {
                handleEdit(row.original);
              }}
              css={{ borderRadius: '4px' }}
            >
              {ConfigurationFormData.details}
            </Button>
          );
        },
      },
      {
        Header: 'Parameter Category',
        accessor: 'parameterCategory',
        disableSortBy: false,
        width: 300,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '300px',
              }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Parameter Name',
        accessor: 'parameterCategory_Name',
        disableSortBy: false,
        width: 300,
      },
      {
        Header: 'Parameter Value',
        accessor: 'PARM_VAL',
        disableSortBy: false,
        width: 250,
      },
      {
        Header: 'ATR1',
        accessor: 'ATR1',
        disableSortBy: false,
      },
      {
        Header: 'ATR2',
        accessor: 'ATR2',
        disableSortBy: false,
      },
      {
        Header: 'ATR3',
        accessor: 'ATR3',
        disableSortBy: false,
      },
      {
        Header: 'ATR4',
        accessor: 'ATR4',
        disableSortBy: false,
      },
      {
        Header: 'ATR5',
        accessor: 'ATR5',
        disableSortBy: false,
      },
      {
        Header: 'ATR6',
        accessor: 'ATR6',
        disableSortBy: false,
      },
      {
        Header: 'ATR7',
        accessor: 'ATR7',
        disableSortBy: false,
      },
      {
        Header: 'Start Date',
        accessor: 'STRT_DT',
        disableSortBy: false,
      },
    ];
  }, []);

  const searchLabel = [
    {
      fieldName: ConfigurationFormData.value,
      value: searchData.rebateReportYear,
    },
    {
      fieldName: ConfigurationFormData.value1,
      value: searchData.parameterType,
    },
    {
      fieldName: ConfigurationFormData.value2,
      value: searchData.parameterCategory,
    },
  ];

  if (isLoading) return <MlreLoader />;

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col span={12}>
          <DataSearchLabel searchLabel={searchLabel} />
          <MlreDataTable data={tableData} columns={columns} />
        </Grid.Col>
      </Grid>
      {showEditForm ? (
        <ConfigurationSearchForm
          data={selectedRowData}
          value={searchData}
          callbackSubmit={() => {
            return setRefreshData(true);
          }}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};
