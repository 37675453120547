/* eslint-disable no-alert */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Alert } from '@abyss/web/ui/Alert';
import { DataTableSearchLabel, MlreLoader } from '@src/common/MlreAbyssComponent';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { SubscriberViewDetail } from './SubscriberViewDetail';
import { BASE_URL } from '../utils/endpoints';
import { HelpContentSubView } from './utils';
import env from '../../../env.json';

export const SubscriberView = () => {
  const { getLocation } = useRouter();
  const { userRolesGroups } = useAuth();
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [subscriberInfoData, setSubscriberInfoData] = useState(null);
  const { state } = getLocation();
  const [addHistoryData, setAddHistoryData] = useState([]);
  const [subNote, setSubNote] = useState([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSubNotesDisabled, setIsSubNotesDisabled] = useState(false);
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState(false)

  const [triggerAction, setTriggerAction] = useState('');
  const [resetEdit, setResetEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [subscriberData, setSubscriberData] = useState(state?.navigateData);
  const [breadcrums, setBreadcrums] = useState(state?.breadcrums)
  const zip = subscriberData?.sbscrZipCd || '';
  const splitZip = zip?.toString().split('-');
  const formDefaultData = {
    ...subscriberData,
    sbscrZipCd: splitZip?.[0],
    sbscrZipExtCd: splitZip?.[1] || '',
  };
  const form = useForm({ defaultValues: formDefaultData });
  const [addressFormDefaultData, setAddressFormDefaultData] =
    useState(formDefaultData);

  useEffect(() => {
    if (subscriberData) {
      localStorage.setItem('subscriberData', JSON.stringify(subscriberData));
    }
    if (form) {
      localStorage.setItem(
        'subscriberViewFormData',
        JSON.stringify(form.getValues())
      );
    }

    if (breadcrums) {
      localStorage.setItem('Breadcrums', JSON.stringify(breadcrums))
    }

  }, [subscriberData, form]);

  useEffect(() => {
    const savedSubscriberData = localStorage.getItem('subscriberData');
    const savedBreadcrums = localStorage.getItem('Breadcrums')


    if (savedSubscriberData) {
      setSubscriberData(JSON.parse(savedSubscriberData));
      const parsedData = JSON.parse(savedSubscriberData);
      form.reset(parsedData);
    }

    if (savedBreadcrums) {
      setBreadcrums(JSON.parse(savedBreadcrums))

    }
  }, []);



  // const breadcrums = state?.breadcrums;
  const subSrcSystemContrId = state?.subSrcSystemContrId;
  const btnRef = useRef(null);

  const onSubmit = async (formData) => {
    setSaveIsButtonDisabled(true);
    const isUpdated =
      JSON.stringify(formData) !== JSON.stringify(addressFormDefaultData);

    if (isUpdated) {
      const payload = {
        ...formData,
        sbscrAdrLn1: encodeURI(formData.sbscrAdrLn1).replaceAll('%', 'perct'),
        sbscrAdrLn2: encodeURI(formData.sbscrAdrLn2).replaceAll('%', 'perct'),
        sbscrAdrLn3: encodeURI(formData.sbscrAdrLn3).replaceAll('%', 'perct'),
        custContrSbscrId: subscriberDetails?.custContrSbscrId,
        modifiedDate: addHistoryData[0].modifiedDate
      };
      try {
        const { data, error, message } = await axios.put(
          `${BASE_URL}subscriber/save`,
          payload,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (!error) {
          if (data === 'Modification error') {
            alert('Error: This data is already modified by other user')
          } else {
            fetchData();
            alert(data);
            setIsUpdateDisabled(true);
            setSaveIsButtonDisabled(false);
            setAddressFormDefaultData(formData);
            setResetEdit(true);
            setTimeout(() => {
              setResetEdit(false);
            }, 500);
          }
        } else {
          alert('An error occurred while saving the address.');
          setSaveIsButtonDisabled(false);
        }
      } catch (error) {
        console.log(error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
        setSaveIsButtonDisabled(false);
      }
    } else {
      alert('No changes to save');
      setSaveIsButtonDisabled(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await axios.get(
        `${BASE_URL}subscriber/${subscriberData.custContrSbscrId}/${subscriberData.year}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!error) {
        setSubscriberInfoData(data);
      }
      setAddHistoryData(data?.subscriberHistory);
      setSubNote(data?.subscriberNotes);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (subscriberData) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const hasUpdateAccess = env.Subscriber_Update_policy.some((role) => {
      return userRolesGroups.includes(role);
    });
    const hasNotesUpdateAccess = env.Subscriber_Notes.some((role) => {
      return userRolesGroups.includes(role);
    });

    const hasEditAccess = env.Subscriber_Notification_Status_Edit.some((role) => {
      return userRolesGroups.includes(role);
    });
    setIsEditDisabled(!hasEditAccess);

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }

    if (!hasNotesUpdateAccess) {
      setIsSubNotesDisabled(true);
    } else {
      setIsSubNotesDisabled(false);
    }
  }, [userRolesGroups, env]);

  const subscriberDetails = subscriberInfoData?.subscriberDetails?.[0];

  const premiumAmount = subscriberData?.totPremCntrbAmt?.toLocaleString(
    'en-US',
    {
      style: 'currency',
      currency: 'USD',
    }
  );

  const middleName = subscriberData?.sbscrMidlInitNm || '';
  const subscriberInfo = [
    {
      name: 'Subscriber ID',
      value: subscriberData?.altId,
    },
    {
      name: 'Subscriber Name',
      value: `${subscriberData?.sbscrFstNm} ${middleName} ${subscriberData?.sbscrLstNm}`,
    },
    {
      name: 'Customer ID',
      value: subscriberData?.srcSysCustId,
    },
    {
      name: 'Premium Contribution',
      value: premiumAmount,
    },
  ];

  const searchLabel = [
    { fieldName: 'Source System', value: subscriberData?.srcSysCd },
    {
      fieldName: 'Policy#',
      value: subscriberData?.searchData?.policy || subSrcSystemContrId,
    },
  ];

  const handleCancel = () => {
    const formData = form.getValues();
    const isUpdated =
      JSON.stringify(formData) !== JSON.stringify(addressFormDefaultData);
    if (isUpdated) {
      const confirmed = window.confirm(
        'Are you sure you want to cancel without saving changes?'
      );
      if (confirmed) {
        form.reset(addressFormDefaultData);
        setIsUpdateDisabled(true);
        setResetEdit(true);
        setTimeout(() => {
          setResetEdit(false);
        }, 500);
      }
    } else {
      form.reset(addressFormDefaultData);
      setIsUpdateDisabled(true);
      setResetEdit(true);
      setTimeout(() => {
        setResetEdit(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (triggerAction) {
      switch (triggerAction) {
        case 'update':
          setIsUpdateDisabled(false);
          break;

        case 'cancel':
          handleCancel();
          break;

        case 'save':
          // const formData = form.getValues();
          // onSubmit(formData);
          btnRef.current.click();
          break;

        case 'reset':
          form.reset(addressFormDefaultData);
          break;

        default:
          break;
      }
    }
  }, [triggerAction]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              {breadcrums ? (
                <SubHeader
                  breadItem={[
                    ...breadcrums,
                    {
                      title: 'Subscriber View',
                      href: '/subscriber-view/',
                      state: { subscriberData },
                    },
                  ]}
                  infoTitle="Subscriber Details"
                  InfoContent={HelpContentSubView}
                />
              ) : (
                ''
              )}
              <DataTableSearchLabel searchLabel={searchLabel} />
              <br />
              <Grid>
                <Grid.Col span={{ xs: 12, md: 6 }}>
                  <Grid>
                    {subscriberInfo.map((info) => {
                      return (
                        <React.Fragment>
                          <Grid.Col span={{ xs: 6, md: 6 }}>
                            <Text fontWeight="bold">{info.name}</Text>
                          </Grid.Col>
                          <Grid.Col span={{ xs: 6, md: 6 }}>
                            <Text>: {info.value}</Text>
                          </Grid.Col>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 6 }}>
                  <Grid>
                    <Grid.Col span={{ xs: 11, md: 9 }}>
                      {/* <Alert
                        title="Subscriber Address"
                        variant="success"
                        isVisible={isUpadateSucess}
                      >
                        Address updated sucessfully
                      </Alert> */}
                      <FormProvider state={form} onSubmit={onSubmit}>
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label="Address"
                          model="sbscrAdrLn1"
                          maxLength={60}
                          validators={{ required: true }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label=""
                          maxLength={60}
                          model="sbscrAdrLn2"
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label=""
                          maxLength={60}
                          model="sbscrAdrLn3"
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label="City"
                          maxLength={60}
                          model="sbscrCtyNm"
                          validators={{ required: true }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label="State"
                          maxLength={10}
                          model="sbscrStCd"
                          validators={{ required: true }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label="ZIP Code"
                          model="sbscrZipCd"
                          maxLength={5}
                          validators={{ required: true }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label=""
                          maxLength={4}
                          model="sbscrZipExtCd"
                        />
                        <Button
                          type="submit"
                          ref={btnRef}
                          style={{ display: 'none' }}
                        >
                          Sub
                        </Button>
                      </FormProvider>
                      <br />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1, md: 3 }}>
                      <Tooltip content="History">
                        <Button
                          rounded
                          size="$sm"
                          css={{ backgroundColor: 'none !important' }}
                        >
                          <IconMaterial
                            css={{ cursor: 'pointer' }}
                            icon="history"
                            onClick={() => {
                              setShowHistory(true);
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
              {
                isLoading ? <MlreLoader /> :

                  <SubscriberViewDetail
                    showHistory={showHistory}
                    onCallBack={() => {
                      setShowHistory(false);
                    }}
                    subscriberData={subscriberData}
                    historyTableData={addHistoryData}
                    subNoteData={subNote}
                    subDetails={subscriberDetails}
                    isSubNotesDisabled={isSubNotesDisabled}
                    isSaveButtonDisable={isSaveButtonDisabled}
                    isButtonDisabled={isButtonDisabled}
                    breadcrums={breadcrums}
                    isEditDisabled={isEditDisabled}
                    triggerAction={(action) => {
                      setTriggerAction(action);
                    }}
                    resetEdit={resetEdit}
                  />
              }
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(SubscriberView);
